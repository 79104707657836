import React from 'react';

import './header.scss';

export function Header() {
  return (
    <header>
      <h1>superatomic</h1>
    </header>
  );
}
